.Tournament {
    width: 100%;
    height: fit-content;

    .row {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            'banner banner banner banner info info'
            'fixture fixture fixture fixture info info';
        gap: 10px;

        .item1 {
            grid-area: banner;
        }

        .item2 {
            grid-area: info;
            height: calc(100vh - 100px);
            overflow: hidden;
            position: sticky;
            // height: 100%; 
            top: 0;
            display: flex;
            flex-direction: column;
            margin-left: 8px;

            @media (max-width:991px) {
                height: fit-content;
                position: relative;

                .subItem2 {
                    display: none;
                }
            }

            .infoContainer {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                .subItem1 {
                    height: fit-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    cursor: pointer;
                }

                .subItem2 {
                    height: -webkit-fill-available;
                    overflow: hidden;
                    padding-top: 10px;

                }

                .liveChat {
                    width: 100%;
                    background-color: #0D0D1CD9;
                }



                .liveChat {
                    width: 100%;
                    height: 100%;
                    padding: 15px 20px;
                    overflow: hidden;
                    border-radius: 18px;
                    display: flex;
                    flex-direction: column;


                    .chatContainer {
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        display: flex;
                        flex-direction: column;


                        .chat {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;

                            .userImage .image {
                                width: 40px;
                                height: 40px;
                                border-radius: 100%;
                            }

                            .userInfo {
                                padding-left: 10px;
                            }
                        }

                    }

                    .messageContainer {
                        width: 100%;
                        padding: 12px 0 0;
                        display: flex;
                        flex-direction: row;

                        .sendButton {
                            width: fit-content;
                            margin-left: 10px;
                            display: flex;
                            color: white;
                            align-items: center;
                            justify-content: center;
                        }
                    }


                }
            }

            .noMatch {
                color: white;
                font-weight: 700;
            }
        }

        .item3 {
            grid-area: fixture;

            .matchesContainer {
                width: 100%;
                background-color: #0D0D1C;
                padding: 5px 10px;
                margin: auto;

                .match {
                    width: 100%;
                    padding: 8px 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #181829;
                    border-radius: 18px;



                    @media (min-width:768px) and (max-width:991px) {
                        .teamContainer {
                            flex-direction: column !important;

                            .team {
                                flex-direction: column !important;
                                justify-content: center !important;
                                align-items: center !important;

                                &.teamInfo1 {
                                    margin-right: auto;
                                    margin-left: 5px;
                                }

                                &.teamInfo2 {
                                    margin-left: auto;
                                    margin-right: 5px;

                                    .name {
                                        order: 2 !important
                                    }
                                }

                                .name {

                                    &.team1,
                                    &.team2 {
                                        margin: 0 !important;
                                    }
                                }
                            }
                        }

                        .separator {
                            margin: 5px 0 !important;
                        }
                    }

                    .teamContainer {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;


                        .team {
                            display: flex;
                            flex-direction: row;

                            @media (min-width:768px) and (max-width:991px) {}

                            img.teamLogo {
                                width: 35px;
                                height: 35px;
                                border-radius: 100%;
                            }

                            span.name {
                                display: flex;
                                align-items: center;
                                color: white;
                                font-size: .7rem;
                                font-weight: 500;


                                &.team1 {
                                    margin-left: 10px;
                                }

                                &.team2 {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    .separator {
                        padding: 8px 10px;
                        display: inline;
                        background-color: #2E2E41;
                        border-radius: 100%;
                        color: #B9E93D;
                        margin: 0 10px;
                        font-size: 0.7rem;
                        font-weight: 500;
                    }

                }
            }
        }

        @media(max-width:991px) {
            grid-template-areas:
                'banner banner banner banner banner banner'
                'info info info info info info'
                'fixture fixture fixture fixture fixture fixture';
        }
    }

    .floatingButtonContainer {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 5;
    }

    .floatingButton {
        // border-radius: 50%;
        // width: 60px;
        // height: 60px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* Hide button on medium screens */
    @media (min-width: 990px) {
        .floatingButtonContainer {
            display: none;
        }
    }


}

.modalContainer {
    width: 550px;
    position: relative;
    z-index: 3;

    .editMatchContainer {
        border-radius: 18px;
        padding: 25px 40px;
        background-color: #181829;

        .buttonContainer {
            margin-top: 15px;
            display: flex;
            flex-direction: row;

        }
    }
}

span.deleteBadge {
    width: 100px;
    height: fit-content;
    padding: 4px 15px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ADD8E6;
    border-radius: 18px;
    color: red;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
}