.datePicker{
    width: 99%;
}

/* all elements inside .datePicker */
.datePicker *{
    color: white;
}

.datePicker div{
    border: none !important;
    color: white;
}

.datePicker > div{
    border: none !important;
}


.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline{
    border-color: none !important;
    border-width:0px !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    border-radius: 16px;
}



/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    color: white !important;
} */

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
    color: white !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    color: white !important;
    padding: 14.5px 14px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
    color: white !important; 
    opacity: 1 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important; 
}

.css-egioen-MuiFormControl-root-MuiTextField-root{
    border-radius: 16px !important;
}


