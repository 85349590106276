.matchInfo {
    width: 100%;
    background-color: #0D0D1CD9;
    display: flex;
    flex-direction: column;
    padding: 15px 8px;

    .editable {
        cursor: pointer;
    }

    .teamContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .team {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .logo {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                margin-bottom: 10px;
            }
        }

        .seperator {
            display: flex;
            flex-direction: column;
            align-items: center;


            .vs {
                margin-top: 10px;
                padding: 6px 9px;
                display: inline;
                background-color: #2E2E41;
                border-radius: 100%;
                color: #B9E93D;
                font-size: 0.6rem;
                font-weight: 500;
            }
        }
    }

    .statsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 10px;

        .team {
            margin-top: auto;
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .stats {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .ballContainer {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
            }

            .stat {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 5px;
            }
        }
    }

}