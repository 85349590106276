
.liveChat {
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    overflow: hidden;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    background-color: #0D0D1CD9;


    .chatContainer {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;


        .chat {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .userImage .image {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }

            .userInfo {
                padding-left: 10px;
            }
        }

    }

    .messageContainer {
        width: 100%;
        padding: 12px 0 0;
        display: flex;
        flex-direction: row;

        .sendButton {
            width: fit-content;
            margin-left: 10px;
            display: flex;
            color: white;
            align-items: center;
            justify-content: center;
        }
    }


}