/* Select Classes */
.ant-select-selector {
    background-color: #0D0D1C !important;
    border: 1px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 5px 0px 15px 0px;
    width: 100%;
}

.ant-select-selection-placeholder {
    color: white !important;
}

.ant-select-arrow,
.ant-select-selection-item-remove {
    color: white !important;
}

.ant-select-arrow {
    margin-top: -10px !important;
}

.ant-select-selection-item {
    background: #48556F !important;
}

.rc-virtual-list {
    background-color: #0D0D1C;
    border-radius: 8px;
}

.ant-select-item {
    color: white !important;

}

/* .rc-virtual-list-holder-inner{
    color: white !important;
} */

.ant-select-item-option-selected {
    color: black !important;
}

.ant-select-arrow {
    padding-right: 5px;
}

.ant-select-dropdown {
    padding: 2px !important;
}


/* Upload Classes */
.ant-upload-drag-container img {
    width: 100%;
    max-width: 50%;
    height: auto;
}

.ant-upload-drag-container {
    background-color: #0D0D1C !important;
}

.ant-upload-drag {
    background-color: #0D0D1C !important;
    border: 1px solid #48556F !important;
    border-radius: 16px !important;
    display: flex;
    justify-content: center;
}

.ant-upload-drag:hover {
    border: 1px solid #29A5DE !important;
}

.ant-upload-drag-icon {
    color: white;
    font-size: xx-large;
}

.ant-upload-text {
    color: white !important;
    line-height: 1;
    font-weight: 600;
}

.ant-upload-hint {
    color: white !important;
    line-height: 1;
    font-size: smaller !important;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 8px !important;
}

