.TournamentBanner {
    width: 100%;
    position: relative;

    .backButton {
        width: fit-content;
        border: 1px solid #48556F;
        padding: 8px;
        font-size: 1.2rem;
        border-radius: 100%;
        display: inline-flex;
        color: white;
        cursor: pointer;
        z-index: 3;
        position: absolute;
        left: 12px;
        top: 12px;
    }

    .bannerImage {
        width: 100%;
        aspect-ratio: 14/6;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 18px;
    }

    .shadowContainer {
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, .5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-radius: 18px;
    }

    .content {
        width: 100%;
        padding: 0 25px 25px 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;

        @media (max-width:991px) {
            padding: 0 10px 10px 10px;
        }

        @media (max-width:991px) {
            padding: 0 10px 10px 10px;
        }

        img.logo {
            width: 60px;
            height: 60px;
            border-radius: 100%;

            @media (max-width:991px) {
                width: 40px;
                height: 40px;
            }
        }

        .info {
            margin-left: 10px;

            .titleContainer,
            .descriptionContainer {
                display: flex;
                flex-direction: row;
                align-items: center;

                span.liveBadge {
                    height: fit-content;
                    padding: 4px 15px;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FF3C3C;
                    border-radius: 18px;
                    color: white;
                    font-size: 10px;
                }

                span.deleteBadge {
                    height: fit-content;
                    padding: 4px 15px;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #ADD8E6;
                    border-radius: 18px;
                    color: red;
                    font-size: 20px;
                    font-weight: 900;
                }

                span.mapIcon {
                    display: flex;
                    margin-right: 5px;
                    color: #246BFD;
                }
            }

            .descriptionContainer {
                padding-right: 5px;
            }
        }
    }
}

.contentdelete {
    display: flex;
    justify-content: space-between;
}